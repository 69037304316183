.custom-select,
.form-control {
  &.date,
  &.datetime,
  &.time {
    &:first-of-type { margin-left: 0 !important; }
    &:last-of-type { margin-right: 0 !important; }
  }
}

.custom-select {
  &[multiple],
  &:only-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
