.devise_custom{

  background-color: #000;
  main{
    & > div{
    }
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    .login-container {
      max-width: 540px;
    }
    .invalid-feedback{
      display: none !important;
      //text-align: center;
      //color: #dc3545;
      //color: rgba(11, 67, 102, 0.8);
    }
    .custom-invalid-feedback {
      margin-bottom: 0;
      font-size: 0.8em;
      text-align: center;
    }
    .simple_form .form-group {
      padding:0 1em;
    }

    .form-control {
      width: 100%;
      border:0;
      background: transparent;
      border-bottom: 2px dotted rgba(11, 67, 102, 0.3);
      box-shadow: none;
      padding: 0 0.02em;
      font-size: 1.25em;
      margin: 0.5em 0 0 0;
      height: 2.6em !important;
      text-align: center;
      &:focus {
        background: transparent;
        box-shadow: none;

      }
    }

    .btn-primary {
      margin-top: 2em;
      color: rgba(0, 0, 0, .7);
      svg{
        color: rgba(0, 0, 0, .7);
      }
      border-color: rgba(255,255,255,0.26);
      border-radius: 30px;
      font-size: 1.4em;
      padding: 5px 20px;
      &:hover{
        color: rgba(0, 0, 0, .9);
        svg{
          color: rgba(0, 0, 0, .9);
        }
      }
      &:active:hover, &:focus {
        color: #000;
      }
      &.reset-password{
        max-width:350px;
        display: block;
        margin-bottom: 20px;
        margin-top: 30px;
      }
    }
    .shared-links {
      margin-top: 3rem;
      text-align: center;
      span {
        text-shadow: 1px 1px 1px rgba(11, 67, 102, 0.1);
        font-size: 1.25rem;
        line-height: 2.25rem;
        letter-spacing: 0.02em;
      }
      a {
        font-size: 1.25rem;
        line-height: 2.25rem;
        letter-spacing: 0.02em;
        text-shadow: 1px 1px 1px rgba(11, 67, 102, 0.1);
        font-weight: 500;
        text-decoration: underline;
        &:hover{
          color:rgba(11, 67, 102,0.9);
        }
      }
    }
  }
  .new-password{
    .session-email{
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  &.devise_custom-sessions, &.devise_custom-passwords, &.devise_custom-registrations.thanks, &.devise_custom-confirmations{
    .main{
      .level-1{
        color: #fff;
        text-align: center;
        margin-bottom: 5rem;
        h1{
          font-size: 3.5rem;
          margin-bottom: 1rem;
        }
        h2{
          font-size: 1.2rem;
          color: rgba(255, 255, 255, 0.7);
        }
      }
      form{
        input, select{
          color: #fff;
          font-size: 1.2rem;
          border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
          background-image: none;
          &::placeholder{
            font-size: 1.2rem;
            color: rgba(255, 255, 255, 0.5);
          }
        }
        a{
          color: rgba(255, 255, 255, 0.7);
          &:hover{
            color: #fff;
            text-decoration: underline;
            svg{
              color: #fff;
            }
          }
          svg{
            color: rgba(255, 255, 255, 0.7);
          }
        }
        .form-group-invalid{
          .invalid-feedback{
            display: block !important;
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
      .btn{
        background-color: rgba(255,255,255,0.7);
        &:hover{
          background-color: rgba(255,255,255,0.9);
        }
        text-transform: none;
      }
      .shared-links{
        span, a{
          color: rgba(255, 255, 255, 0.7);
        }
        a:hover{
          color: #fff;
          text-decoration: underline;
        }
      }
      .new-password{
        color: rgba(255, 255, 255, 0.7);
        font-size: 1rem;

      }
      .thanks-container{
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.2rem;
        strong{
          color: #fff;
        }
      }
    }
  }

  .input-group-append {
    display: none;
  }

  .user_privacy, .user_terms_and_conditions, .user_statute {
    a {
      text-decoration: underline;
    }
  }

  .input-group-addon {
    display: none;
  }

}

.iziToast-wrapper{
  .devise{
    .iziToast-icon{
      font-size: 2.5rem;
    }
    .iziToast-message{
      margin-left: 1.5rem;
      font-size: 1.2rem;
      line-height: 1.5rem;
      strong{
        color: #fff;
        font-weight: bold;
      }
    }
  }
}