$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import "~bootstrap/scss/bootstrap";
@import "~izitoast/dist/css/iziToast.min";

@import "./simple_form-bootstrap/simple_form-bootstrap";
@import "./app/general";
@import "./app/devise";
